import Vue from "vue";
import Router from "vue-router";
// Containers or template
const TheContainer = () => import("@/containers/TheContainer");
const TheContainerNoSession = () => import("@/no_session/TheContainer");
const BlankContainer = () => import("@/no_session/BlankContainer");
const TheContainerPemohon = () => import("@/pemohon/TheContainerPemohon");
// Views
const Dashboard = () => import("@/views/Dashboard");
const Logout = () => import("@/views/Logout");
const ProfileAdmin = () => import("@/views/account/ProfileAdmin");
// Views - Pages
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");
const LupaPassword = () => import("@/views/pages/LupaPassword");
const ResetPassword = () => import("@/views/pages/ResetPassword");
// Views - Permohonan
const DetailPermohonanPemasukan = () =>
  import("@/views/permohonan/DetailPermohonanPemasukan");
const DetailPermohonanPengeluaran = () =>
  import("@/views/permohonan/DetailPermohonanPengeluaran");
// Format Detail Baru Admin
const DetailPermohonanPemasukanHorti = () =>
  import("@/views/permohonan/hortikultura/DetailPemasukanHorti"); // Admin
const DetailPermohonanPengeluaranHorti = () =>
  import("@/views/permohonan/hortikultura/DetailPengeluaranHorti"); // Admin
const DetailPermohonanPemasukanPerkebunan = () =>
  import("@/views/permohonan/perkebunan/DetailPemasukanPerkebunan"); // Admin
const DetailPermohonanPengeluaranPerkebunan = () =>
  import("@/views/permohonan/perkebunan/DetailPengeluaranPerkebunan"); // Admin
const DetailPermohonanPemasukanSdg = () =>
  import("@/views/permohonan/sumber-daya-genetik/DetailPemasukanSdg"); // Admin
const DetailPermohonanPengeluaranSdg = () =>
  import("@/views/permohonan/sumber-daya-genetik/DetailPengeluaranSdg"); // Admin
const DetailPermohonanPemasukanTanamanPangan = () =>
  import("@/views/permohonan/tanaman-pangan/DetailPemasukanTanamanPangan"); // Admin
const DetailPermohonanPengeluaranTanamanPangan = () =>
  import("@/views/permohonan/tanaman-pangan/DetailPengeluaranTanamanPangan"); // Admin
const DetailPermohonanPemasukanHpt = () =>
  import("@/views/permohonan/hijauan-pakan-ternak/DetailPemasukanHpt"); // Admin
const DetailPermohonanPengeluaranHpt = () =>
  import("@/views/permohonan/hijauan-pakan-ternak/DetailPengeluaranHpt"); // Admin
// End Format Detail Baru Admin

const ListPermohonan = () => import("@/views/permohonan/ListPermohonan");
// Views - Master Kelompok tanaman
const AllKelompokJenisTanaman = () =>
  import("@/views/master/kelompok_jenis_tanaman/AllKelompokJenisTanaman");
const AddKelompokJenisTanaman = () =>
  import("@/views/master/kelompok_jenis_tanaman/AddKelompokJenisTanaman");
const AllSubKelompokJenisTanaman = () =>
  import(
    "@/views/master/sub_kelompok_jenis_tanaman/AllSubKelompokJenisTanaman"
  );
const AllJenisTanaman = () =>
  import("@/views/master/jenis_tanaman/AllJenisTanaman");
// Views - Master Negara
const AllNegara = () => import("@/views/master/negara/AllNegara");
const AddNegara = () => import("@/views/master/negara/AddNegara");
const Editnegara = () => import("@/views/master/negara/EditNegara");
// Views - Master Module
const AllModule = () => import("@/views/master/module/AllModule");
const AddModule = () => import("@/views/master/module/AddModule");
const EditModule = () => import("@/views/master/module/EditModule");
// Views - Master Modul Kategori
const AllModuleCategory = () =>
  import("@/views/master/module_cat/AllModuleCategory");
// Views - Master Pelabuhan
const AllPelabuhan = () => import("@/views/master/pelabuhan/AllPelabuhan");
// Views - Master Tipe Pemohon
const AllTipePemohon = () =>
  import("@/views/master/tipe_pemohon/AllTipePemohon");
// Views - Master User
const AllUser = () => import("@/views/master/user/AllUser");
const AddUser = () => import("@/views/master/user/AddUser");
const EditUser = () => import("@/views/master/user/EditUser");
// View - Pemohon
const DashboardPemohon = () => import("@/views/pemohon/Dashboard");
const ProfilePemohon = () => import("@/views/pemohon/ProfilePemohon");
const DetailPermohonanPemasukanPemohon = () =>
  import("@/views/pemohon/DetailPermohonanPemasukanPemohon");
const DetailPermohonanPengeluaranPemohon = () =>
  import("@/views/pemohon/DetailPermohonanPengeluaranPemohon");
// Format Detail Baru Pemohon
const DetailPemohonPemasukanHorti = () => import("@/views/pemohon/permohonan/hortikultura/DetailPemasukanHorti"); // Pemohon
const DetailPemohonPengeluaranHorti = () => import("@/views/pemohon/permohonan/hortikultura/DetailPengeluaranHorti"); // Pemohon
const DetailPemohonPemasukanPerkebunan = () => import("@/views/pemohon/permohonan/perkebunan/DetailPemasukanPerkebunan"); // Pemohon
const DetailPemohonPengeluaranPerkebunan = () => import("@/views/pemohon/permohonan/perkebunan/DetailPengeluaranPerkebunan"); // Pemohon
const DetailPemohonPemasukanSdg = () => import("@/views/pemohon/permohonan/sumber-daya-genetik/DetailPemasukanSdg"); // Pemohon
const DetailPemohonPengeluaranSdg = () => import("@/views/pemohon/permohonan/sumber-daya-genetik/DetailPengeluaranSdg"); // Pemohon
const DetailPemohonPemasukanTanamanPangan = () => import("@/views/pemohon/permohonan/tanaman-pangan/DetailPemasukanTanamanPangan"); // Pemohon
const DetailPemohonPengeluaranTanamanPangan = () => import("@/views/pemohon/permohonan/tanaman-pangan/DetailPengeluaranTanamanPangan"); // Pemohon
const DetailPemohonPemasukanHPT = () => import("@/views/pemohon/permohonan/hijauan-pakan-ternak/DetailPemasukanHPT"); // Pemohon

// End Format Detail Baru Pemohon
// Edit permohonan ajukan ulang
const EditPermohonanPemasukanPemohon = () =>
  import("@/views/pemohon/EditPermohonanPemasukanPemohon");
const EditPermohonanPengeluaranPemohon = () =>
  import("@/views/pemohon/EditPermohonanPengeluaranPemohon");
// Edit permohonan draft
const EditPemasukanDraft = () =>
  import("@/views/pemohon/permohonan/EditPemasukanDraft");
const EditPengeluaranDraft = () =>
  import("@/views/pemohon/permohonan/EditPengeluaranDraft");
const AjukanPermohonan = () => import("@/views/pemohon/AjukanPermohonan");
const PermohonanPemohon = () =>
  import("@/views/pemohon/permohonan/PermohonanPemohon");
const FormPengajuan = () => import("@/views/pemohon/FormPengajuan");
const FormPengajuanPengeluaran = () =>
  import("@/views/pemohon/FormPengajuanPengeluaran");
// Laporan Pemohon
const LaporanPemohonSelesai = () =>
  import("@/views/pemohon/laporan/LaporanPemohonSelesai");
const AddLaporanPemohonRealisasi = () =>
  import("@/views/pemohon/laporan/AddLaporanPemohonRealisasi");
const LaporanPemohonRealisasi = () =>
  import("@/views/pemohon/laporan/LaporanPemohonRealisasi");
// Laporan
const AllJadwalLiburPelayanan = () =>
  import("@/views/master/jadwal_pelayanan_libur/AllJadwalLiburPelayanan");
const AddJadwalLiburPelayanan = () =>
  import("@/views/master/jadwal_pelayanan_libur/AddJadwalLiburPelayanan");
const EditJadwalLiburPelayanan = () =>
  import("@/views/master/jadwal_pelayanan_libur/EditJadwalLiburPelayanan");
if (localStorage.dateLogin) {
  let GivenDate = localStorage.dateLogin;
  let CurrentDate = new Date();
  GivenDate = new Date(GivenDate);
  if (GivenDate > CurrentDate) {
    localStorage.clear();
    alert("Session timeout");
  }
}
Vue.use(Router);
export default new Router({
	mode: "hash", // https://router.vuejs.org/api/#mode
	linkActiveClass: "active",
	scrollBehavior: () => ({ y: 0 }),
	routes: configRoutes(),
});
function configRoutes() {
	return [
		{
			path: "/",
			redirect: "/pages/login",
			name: "Home",
			component: TheContainer,
			children: [
				{
					path: "dashboard",
					name: "Dashboard",
					component: Dashboard,
				},
				
				{
					path: "logout",
					name: "LogoutAdmin",
					component: Logout,
				},
				{
					path: "profile",
					name: "ProfileAdmin",
					component: ProfileAdmin,
				},
				
				{
					path: "konfigurasi",
					name: "Konfigurasi",
					component: () => import("@/views/master/konfigurasi/AllKonfigurasi"),
				},
			],
		},
		{
			path: "/pages",
			redirect: "/pages/404",
			name: "Pages",
			component: TheContainerNoSession,
			children: [
				{
					path: "login",
					name: "Login",
					component: Login,
				},
				{
					path: "auto-login",
					name: "auto-login", 
					component: () => import("@/views/pages/AutoLogin"),
				},
				{
					path: "register",
					name: "Register",
					component: Register,
				},
				{
					path: "lupa-password",
					name: "LupaPassword",
					component: LupaPassword,
				},
				{
					path: "reset-password",
					name: "ResetPassword",
					component: ResetPassword,
				},
				{
					path: "jawaban-faq/:idFaq",
					name: "JawabanFaq",
					component: () => import("@/views/pemohon/JawabanFaq"),
				},
				{
					path: "pusat-bantuan",
					name: "PusatBantuan",
					component: () => import("@/views/pemohon/PusatBantuan"),
				},
			],
		},
		{
			path: "/laporan",
			name: "Laporan",
			component: TheContainer,
			children: [
				{
					path: "laporan-pemasukan-data-benih",
					name: "LaporanPemasukanDataBenih",
					component: () => import("@/views/laporan/LaporanDataBenihPemasukan"),
				},
				{
					path: "laporan-pengeluaran-data-benih",
					name: "LaporaPengeluarannDataBenih",
					component: () => import("@/views/laporan/LaporanDataBenihPengeluaran"),
				},
				{
					path: "laporan-realisasi",
					name: "LaporanRealisasi",
					component: () => import("@/views/laporan/LaporanRealisasi"),
				},
				{
					path: "detail-realisasi/:idrealisasi",
					name: "DetailRealisasi",
					component: () => import("@/views/laporan/DetailRealisasi"),
				},
				{
					path: "laporan-sip-pemasukan",
					name: "LaporanSIP-pemasukan",
					component: () => import("@/views/laporan/LaporanPemasukanSIP"),
				},
				{
					path: "laporan-sip-pengeluaran",
					name: "LaporanSIP-pengeluaran",
					component: () => import("@/views/laporan/LaporanPengeluaranSIP"),
				},
				{
					path: "log-aktifitas",
					name: "LogAktifitas",
					component: () => import("@/views/laporan/LogAktifitas"),
				},
        {
          path: "dashboard-estimasi-permohonan",
          name: "DashboardEstimasiPermohonan",
          component: () =>
            import("@/views/laporan/DashboardEstimasiPermohonan"),
        },
        {
          path: "laporan-kinerja",
          name: "LaporanKinerja",
          component: () => import("@/views/laporan/LaporanKinerja"),
        },
			],
		},
		{
			path: "/permohonan",
			redirect: "/pages/404",
			name: "Permohonan",
			component: TheContainer,
			children: [
				{
					path: "list-permohonan/:pr_kategori",
					name: "ListPermohonan",
					component: ListPermohonan,
				},
				{
					path: "detail-permohonan-pemasukan/:idpermohonan",
					name: "DetailPermohonanPemasukan",
					component: DetailPermohonanPemasukan,
				},
				{
					path: "detail-permohonan-pengeluaran/:idpermohonan",
					name: "DetailPermohonanPengeluaran",
					component: DetailPermohonanPengeluaran,
				},
				
				{
					path: "detail-permohonan-pemasukan/Hortikultura/:idpermohonan",
					name: "DetailPermohonanPemasukanHorti",
					component: DetailPermohonanPemasukanHorti,
				},
				{
					path: "detail-permohonan-pemasukan/Perkebunan/:idpermohonan",
					name: "DetailPermohonanPemasukanPerkebunan",
					component: DetailPermohonanPemasukanPerkebunan,
				},
				{
					path: "detail-permohonan-pemasukan/Sumber-Daya-Genetik/:idpermohonan",
					name: "DetailPermohonanPemasukanSdg",
					component: DetailPermohonanPemasukanSdg,
				},
				{
					path: "detail-permohonan-pemasukan/Tanaman-Pangan/:idpermohonan",
					name: "DetailPermohonanPemasukanTanamanPangan",
					component: DetailPermohonanPemasukanTanamanPangan,
				},
				{
					path: "detail-permohonan-pengeluaran/Hortikultura/:idpermohonan",
					name: "DetailPermohonanPengeluaranHorti",
					component: DetailPermohonanPengeluaranHorti,
				},
				{
					path: "detail-permohonan-pengeluaran/Perkebunan/:idpermohonan",
					name: "DetailPermohonanPengeluaranPerkebunan",
					component: DetailPermohonanPengeluaranPerkebunan,
				},
				{
					path: "detail-permohonan-pengeluaran/Sumber-Daya-Genetik/:idpermohonan",
					name: "DetailPermohonanPengeluaranSdg",
					component: DetailPermohonanPengeluaranSdg,
				},
				{
					path: "detail-permohonan-pengeluaran/Tanaman-Pangan/:idpermohonan",
					name: "DetailPermohonanPengeluaranTanamanPangan",
					component: DetailPermohonanPengeluaranTanamanPangan,
        },
        {
          path: "detail-permohonan-pemasukan/Benih-Tanaman-Pakan-Ternak/:idpermohonan",
          name: "DetailPermohonanPemasukanHPT",
          component: DetailPermohonanPemasukanHpt,
        },
        {
          path: "detail-permohonan-pengeluaran/Benih-Tanaman-Pakan-Ternak/:idpermohonan",
          name: "DetailPermohonanPengeluaranHpt",
          component: DetailPermohonanPengeluaranHpt,
        },
			],
		},
		{
			path: "/pemohon",
			name: "Pemohon",
			component: TheContainerPemohon,
			children: [
				{
					path: "dashboardpemohon",
					name: "DashboardPemohon",
					component: DashboardPemohon,
				},
				{
					path: "pusat-bantuan",
					name: "PusatBantuan",
					component: () => import("@/views/pemohon/PusatBantuan"),
				},
				{
					path: "informasi-kontak",
					name: "InformasiKontak",
					component: () => import("@/views/pemohon/InformasiKontak"),
				},
				{
					path: "jawaban-faq/:idFaq",
					name: "JawabanFaq",
					component: () => import("@/views/pemohon/JawabanFaq"),
				},
				{
					path: "profile-pemohon",
					name: "ProfilePemohon",
					component: ProfilePemohon,
				},
				{
					path: "detail-permohonan-pemasukan/:idpermohonan",
					name: "DetailPermohonanPemasukanPemohon",
					component: DetailPermohonanPemasukanPemohon,
				},
				{
					path: "edit-permohonan-pemasukan/:idpermohonan",
					name: "EditPermohonanPemasukanPemohon",
					component: EditPermohonanPemasukanPemohon,
				},
				{
					path: "edit-permohonan-pengeluaran/:idpermohonan",
					name: "EditPermohonanPengeluaranPemohon",
					component: EditPermohonanPengeluaranPemohon,
				},
				{
					path: "edit-permohonan-pemasukan-draft/:idpermohonan",
					name: "EditPemasukanDraft",
					component: EditPemasukanDraft,
				},
				{
					path: "edit-permohonan-pengeluaran-draft/:idpermohonan",
					name: "EditPengeluaranDraft",
					component: EditPengeluaranDraft,
				},
				{
					path: "detail-permohonan-pengeluaran/:idpermohonan",
					name: "DetailPermohonanPengeluaranPemohon",
					component: DetailPermohonanPengeluaranPemohon,
				},
				{
					path: "detail-permohonan-pemasukan/:idpermohonan",
					name: "DetailPermohonanPemasukanPemohon",
					component: DetailPermohonanPemasukanPemohon,
				},
				{
					path: "detail-permohonan-pemasukan/Hortikultura/:idpermohonan",
					name: "DetailPemohonPemasukanHorti",
					component: DetailPemohonPemasukanHorti,
				},
				{
					path: "detail-permohonan-pemasukan/Perkebunan/:idpermohonan",
					name: "DetailPemohonPemasukanPerkebunan",
					component: DetailPemohonPemasukanPerkebunan,
				},
				{
					path: "detail-permohonan-pemasukan/Sumber-Daya-Genetik/:idpermohonan",
					name: "DetailPemohonPemasukanSdg",
					component: DetailPemohonPemasukanSdg,
				},
				{
					path: "detail-permohonan-pemasukan/Tanaman-Pangan/:idpermohonan",
					name: "DetailPemohonPemasukanTanamanPangan",
					component: DetailPemohonPemasukanTanamanPangan,
				},
				{
					path: "edit-permohonan-pemasukan/:idpermohonan",
					name: "EditPermohonanPemasukanPemohon",
					component: EditPermohonanPemasukanPemohon,
				},
				{
					path: "edit-permohonan-pengeluaran/:idpermohonan",
					name: "EditPermohonanPengeluaranPemohon",
					component: EditPermohonanPengeluaranPemohon,
				},
				{
					path: "edit-permohonan-pemasukan-draft/:idpermohonan",
					name: "EditPemasukanDraft",
					component: EditPemasukanDraft,
				},
				{
					path: "edit-permohonan-pengeluaran-draft/:idpermohonan",
					name: "EditPengeluaranDraft",
					component: EditPengeluaranDraft,
				},
				{
					path: "detail-permohonan-pengeluaran/:idpermohonan",
					name: "DetailPermohonanPengeluaranPemohon",
					component: DetailPermohonanPengeluaranPemohon,
				},
				{
					path: "detail-permohonan-pengeluaran/Hortikultura/:idpermohonan",
					name: "DetailPemohonPengeluaranHorti",
					component: DetailPemohonPengeluaranHorti,
				},
				{
					path: "detail-permohonan-pengeluaran/Perkebunan/:idpermohonan",
					name: "DetailPemohonPengeluaranPerkebunan",
					component: DetailPemohonPengeluaranPerkebunan,
				},
				{
					path: "detail-permohonan-pengeluaran/Sumber-Daya-Genetik/:idpermohonan",
					name: "DetailPemohonPengeluaranSdg",
					component: DetailPemohonPengeluaranSdg,
				},
				{
					path: "detail-permohonan-pengeluaran/Tanaman-Pangan/:idpermohonan",
					name: "DetailPemohonPengeluaranTanamanPangan",
					component: DetailPemohonPengeluaranTanamanPangan,
				},
				{
					path: "ajukan-permohonan",
					name: "AjukanPermohonan",
					component: AjukanPermohonan,
				},
				{
					path: "form-pengajuan-pemasukan",
					name: "FormPengajuan",
					component: FormPengajuan,
				},
				{
					path: "form-pengajuan-pengeluaran",
					name: "FormPengajuanPengeluaran",
					component: FormPengajuanPengeluaran,
				},

				// Format Form Baru
				// Pemasukan Horti
				{
					path: "form-pengajuan-pemasukan/hortikultura",
					name: "FormPengajuanPemasukanHortikultura",
					component: () => import("@/views/pemohon/permohonan/hortikultura/FormPengajuanPemasukanHortikultura"),
				},
				{
					path: "edit-permohonan-pemasukan-draft/hortikultura/:idpermohonan",
					name: "EditPemasukanDraftHortikultura",
					component: () => import("@/views/pemohon/permohonan/hortikultura/EditPemasukanDraftHortikultura"),
				},
				{
					path: "edit-perbaikan-permohonan-pemasukan/hortikultura/:idpermohonan",
					name: "EditPerbaikanPemasukanHortikultura",
					component: () => import("@/views/pemohon/permohonan/hortikultura/EditPerbaikanPemasukanHortikultura"),
				},
				// Pengeluaran Horti
				{
					path: "form-pengajuan-pengeluaran/hortikultura",
					name: "FormPengajuanPengeluaranHortikultura",
					component: () => import("@/views/pemohon/permohonan/hortikultura/FormPengajuanPengeluaranHortikultura"),
				},
				{
					path: "edit-permohonan-pengeluaran-draft/hortikultura/:idpermohonan",
					name: "EditPengeluaranDraftHortikultura",
					component: () => import("@/views/pemohon/permohonan/hortikultura/EditPengeluaranDraftHortikultura"),
				},
				{
					path: "edit-perbaikan-permohonan-pengeluaran/hortikultura/:idpermohonan",
					name: "EditPerbaikanPengeluaranHortikultura",
					component: () => import("@/views/pemohon/permohonan/hortikultura/EditPerbaikanPengeluaranHortikultura"),
				},


				// Pemasukan Perkebunan
				{
					path: "form-pengajuan-pemasukan/perkebunan",
					name: "FormPengajuanPemasukanPerkebunan",
					component: () => import("@/views/pemohon/permohonan/perkebunan/FormPengajuanPemasukanPerkebunan"),
				},
				{
					path: "edit-permohonan-pemasukan-draft/perkebunan/:idpermohonan",
					name: "EditPemasukanDraftPerkebunan",
					component: () => import("@/views/pemohon/permohonan/perkebunan/EditPemasukanDraftPerkebunan"),
				},
				{
					path: "edit-perbaikan-permohonan-pemasukan/perkebunan/:idpermohonan",
					name: "EditPerbaikanPemasukanPerkebunan",
					component: () => import("@/views/pemohon/permohonan/perkebunan/EditPerbaikanPemasukanPerkebunan"),
				},
				// Pengeluaran Perkebunan
				{
					path: "form-pengajuan-pengeluaran/perkebunan",
					name: "FormPengajuanPengeluaranPerkebunan",
					component: () => import("@/views/pemohon/permohonan/perkebunan/FormPengajuanPengeluaranPerkebunan"),
				},
				{
					path: "edit-permohonan-pengeluaran-draft/perkebunan/:idpermohonan",
					name: "EditPengeluaranDraftPerkebunan",
					component: () => import("@/views/pemohon/permohonan/perkebunan/EditPengeluaranDraftPerkebunan"),
				},
				{
					path: "edit-perbaikan-permohonan-pengeluaran/perkebunan/:idpermohonan",
					name: "EditPerbaikanPengeluaranPerkebunan",
					component: () => import("@/views/pemohon/permohonan/perkebunan/EditPerbaikanPengeluaranPerkebunan"),
				},


				// Pemasukan Tanaman Pangan
				{
					path: "form-pengajuan-pemasukan/tanaman-pangan",
					name: "FormPengajuanPemasukanTanamanPangan",
					component: () => import("@/views/pemohon/permohonan/tanaman-pangan/FormPengajuanPemasukanTanamanPangan"),
				},
				{
					path: "edit-permohonan-pemasukan-draft/tanaman-pangan/:idpermohonan",
					name: "EditPemasukanDraftTanamanPangan",
					component: () => import("@/views/pemohon/permohonan/tanaman-pangan/EditPemasukanDraftTanamanPangan"),
				},
				{
					path: "edit-perbaikan-permohonan-pemasukan/tanaman-pangan/:idpermohonan",
					name: "EditPerbaikanPemasukanTanamanPangan",
					component: () => import("@/views/pemohon/permohonan/tanaman-pangan/EditPerbaikanPemasukanTanamanPangan"),
				},
				// Pengeluaran Tanaman Pangan
				{
					path: "form-pengajuan-pengeluaran/tanaman-pangan",
					name: "FormPengajuanPengeluaranTanamanPangan",
					component: () => import("@/views/pemohon/permohonan/tanaman-pangan/FormPengajuanPengeluaranTanamanPangan"),
				},
				{
					path: "edit-permohonan-pengeluaran-draft/tanaman-pangan/:idpermohonan",
					name: "EditPengeluaranDraftTanamanPangan",
					component: () => import("@/views/pemohon/permohonan/tanaman-pangan/EditPengeluaranDraftTanamanPangan"),
				},
				{
					path: "edit-perbaikan-permohonan-pengeluaran/tanaman-pangan/:idpermohonan",
					name: "EditPerbaikanPengeluaranTanamanPangan",
					component: () => import("@/views/pemohon/permohonan/tanaman-pangan/EditPerbaikanPengeluaranTanamanPangan"),
				},

        // Pemasukan Sumber Daya Genetik
        {
          path: "form-pengajuan-pemasukan/sumber-daya-genetik",
          name: "FormPengajuanPemasukanSumberDayaGenetik",
          component: () =>
            import(
              "@/views/pemohon/permohonan/sumber-daya-genetik/FormPengajuanPemasukanSumberDayaGenetik"
            ),
        },
        {
          path: "edit-permohonan-pemasukan-draft/sumber-daya-genetik/:idpermohonan",
          name: "EditPemasukanDraftSumberDayaGenetik",
          component: () =>
            import(
              "@/views/pemohon/permohonan/sumber-daya-genetik/EditPemasukanDraftSumberDayaGenetik"
            ),
        },
        {
          path: "edit-perbaikan-permohonan-pemasukan/sumber-daya-genetik/:idpermohonan",
          name: "EditPerbaikanPemasukanSumberDayaGenetik",
          component: () =>
            import(
              "@/views/pemohon/permohonan/sumber-daya-genetik/EditPerbaikanPemasukanSumberDayaGenetik"
            ),
        },
        // Pengeluaran Sumber Daya Genetik
        {
          path: "form-pengajuan-pengeluaran/sumber-daya-genetik",
          name: "FormPengajuanPengeluaranSumberDayaGenetik",
          component: () =>
            import(
              "@/views/pemohon/permohonan/sumber-daya-genetik/FormPengajuanPengeluaranSumberDayaGenetik"
            ),
        },
        {
          path: "edit-permohonan-pengeluaran-draft/sumber-daya-genetik/:idpermohonan",
          name: "EditPengeluaranDraftSumberDayaGenetik",
          component: () =>
            import(
              "@/views/pemohon/permohonan/sumber-daya-genetik/EditPengeluaranDraftSumberDayaGenetik"
            ),
        },
        {
          path: "edit-perbaikan-permohonan-pengeluaran/sumber-daya-genetik/:idpermohonan",
          name: "EditPerbaikanPengeluaranSumberDayaGenetik",
          component: () =>
            import(
              "@/views/pemohon/permohonan/sumber-daya-genetik/EditPerbaikanPengeluaranSumberDayaGenetik"
            ),
        },
        {
          path: "form-pengajuan-pemasukan/hortikultura",
          name: "FormPengajuanPemasukanHortikultura",
          component: () =>
            import(
              "@/views/pemohon/permohonan/hortikultura/FormPengajuanPemasukanHortikultura"
            ),
        },

        //   Form pemasukan hijauan pakan ternak
        {
          path: "form-pengajuan-pemasukan/Benih-Tanaman-Pakan-Ternak",
          name: "FormPengajuanPemasukanHPT",
          component: () =>
            import(
              "@/views/pemohon/permohonan/hijauan-pakan-ternak/FormPengajuanPemasukanHPT"
            ),
        },
        {
          path: "form-pengajuan-pengeluaran/Benih-Tanaman-Pakan-Ternak",
          name: "FormPengajuanPengeluaranHPT",
          component: () =>
            import(
              "@/views/pemohon/permohonan/hijauan-pakan-ternak/FormPengajuanPengeluaranHPT"
            ),
        },
        {
          path: "detail-permohonan-pemasukan/Benih-Tanaman-Pakan-Ternak/:idpermohonan",
          name: "DetailPemasukanHPT",
          component: DetailPemohonPemasukanHPT,
        },
        {
          path: "edit-perbaikan-permohonan-pemasukan/Benih-Tanaman-Pakan-Ternak/:idpermohonan",
          name: "EditPerbaikanPemasukanHPT",
          component: () =>
            import(
              "@/views/pemohon/permohonan/hijauan-pakan-ternak/EditPerbaikanPemasukanHPT"
            ),
        },
        {
          path: "edit-perbaikan-permohonan-pengeluaran/Benih-Tanaman-Pakan-Ternak/:idpermohonan",
          name: "EditPerbaikanPengeluaranHPT",
          component: () =>
            import(
              "@/views/pemohon/permohonan/hijauan-pakan-ternak/EditPerbaikanPengeluaranHPT"
            ),
        },
        {
          path: "edit-permohonan-pemasukan-draft/Benih-Tanaman-Pakan-Ternak/:idpermohonan",
          name: "EditPemasukanDraftHPT",
          component: () =>
            import(
              "@/views/pemohon/permohonan/hijauan-pakan-ternak/EditPemasukanDraftHPT"
            ),
        },
        {
          path: "edit-permohonan-pengeluaran-draft/Benih-Tanaman-Pakan-Ternak/:idpermohonan",
          name: "EditPengeluaranDraftHPT",
          component: () =>
            import(
              "@/views/pemohon/permohonan/hijauan-pakan-ternak/EditPengeluaranDraftHPT"
            ),
        },
        {
          path: "detail-permohonan-pengeluaran/Benih-Tanaman-Pakan-Ternak/:idpermohonan",
          name: "DetailPengeluaranHPT",
          component: () =>
            import(
              "@/views/pemohon/permohonan/hijauan-pakan-ternak/DetailPengeluaranHPT"
            ),
        },
        // End format form baru

		{
			path: "permohonan/:status",
			name: "PermohonanPemohon",
			component: PermohonanPemohon,
		},
		{
			path: "laporan",
			name: "LaporanPemohon",
			component: {
		        render (c) { return c('router-view') }
		    },
			children: [
				{
					path: "permohonan-selesai",
					name: "LaporanPemohonSelesai",
					component: LaporanPemohonSelesai,
				},
				{
					path: "realisasi",
					name: "LaporanPemohonRealisasi",
					component: LaporanPemohonRealisasi,
				},
				{
					path: "add-realisasi",
					name: "AddLaporanPemohonRealisasi",
					component: AddLaporanPemohonRealisasi,
				},
				{
					path: "add-realisasi-permohonan/:idpermohonan",
					name: "AddLaporanRealisasiByPermohonan",
					component: () => import("@/views/pemohon/laporan/AddLaporanRealisasiByPermohonan"),
				},
				{	
					path: "detail-realisasi/:idrealisasi",
					name: "DetailRealisasiPemohon",
					component: () => import("@/views/pemohon/laporan/DetailRealisasiPemohon"),
				},
			],
		},
		{
			path: "logout",
			name: "LogoutPemohon",
			component: Logout,
		},
	],
		},
		// Master Perusahaan
		{
			path: "/master",
			redirect: "/master/perusahaan",
			name: "Perusahaan",
			component: TheContainer,
			children: [
				{
					path: "perusahaan",
					name: "DaftarPerusahaan",
					component: () => import("@/views/master/perusahaan/DaftarPerusahaan"),
				}, 
				{
					path: "detail-perusahaan/:id_perusahaan",
					name: "detailPerusahaan",
					component: () => import("@/views/master/perusahaan/DetailPerusahaan"),
				}, 
			],
		},
		// Master Kelompok Jenis Tanaman
		{
			path: "/master",
			redirect: "/master/jenis-tanaman",
			name: "Master Jenis Tanaman",
			component: TheContainer,
			children: [
				{
					path: "jenis-tanaman",
					name: "AllJenisTanaman",
					component: AllJenisTanaman,
				},
				{
					path: "add-jenis-tanaman",
					name: "AddJenisTanaman",
					component: () => import("@/views/master/jenis_tanaman/AddJenisTanaman"),
				},
				{
					path: "edit-jenis-tanaman/:idJenisTanaman",
					name: "EditJenisTanaman",
					component: () => import("@/views/master/jenis_tanaman/EditJenisTanaman"),
				},
			],
		},
		// Master Kelompok Jenis Tanaman
		{
			path: "/master",
			redirect: "/master/kelompok-jenis-tanaman",
			name: "Master Kelompok Jenis Tanaman",
			component: TheContainer,
			children: [
				{
					path: "kelompok-jenis-tanaman",
					name: "AllKelompokJenisTanaman",
					component: AllKelompokJenisTanaman,
				},
				{
					path: "add-kelompok-jenis-tanaman",
					name: "AddKelompokJenisTanaman",
					component: AddKelompokJenisTanaman,
				},
				{
					path: "edit-kelompok-jenis-tanaman/:idKelompokTanaman",
					name: "EditKelompokJenisTanaman",
					component: () => import("@/views/master/kelompok_jenis_tanaman/EditKelompokJenisTanaman"),
				},
			],
		},
		// Master Kelompok Jenis Tanaman
		{
			path: "/master",
			redirect: "/master/sub-kelompok-jenis-tanaman",
			name: "Master Sub Kelompok Jenis Tanaman",
			component: TheContainer,
			children: [
				{
					path: "sub-kelompok-jenis-tanaman",
					name: "AllSubKelompokJenisTanaman",
					component: AllSubKelompokJenisTanaman,
				},
				{
					path: "add-sub-kelompok-jenis-tanaman",
					name: "AddSubKelompokJenisTanaman",
					component: () => import("@/views/master/sub_kelompok_jenis_tanaman/AddSubKelompokJenisTanaman"),
				},
				{
					path: "edit-sub-kelompok-jenis-tanaman/:idSubKelompok",
					name: "EditSubKelompokJenisTanaman",
					component: () => import("@/views/master/sub_kelompok_jenis_tanaman/EditSubKelompokJenisTanaman"),
				},
			],
		},
		// Master Negara
		{
			path: "/master",
			redirect: "/master/negara",
			name: "Master Negara",
			component: TheContainer,
			children: [
				{
					path: "negara",
					name: "AllNegara",
					component: AllNegara,
				},
				{
					path: "add-negara",
					name: "AddNegara",
					component: AddNegara,
				},
				{
					path: "edit-negara/:idNegara",
					name: "Editnegara",
					component: Editnegara,
				},
			],
		},
		// Master Module
		{
			path: "/master",
			redirect: "/master/module",
			name: "Master Module",
			component: TheContainer,
			children: [
				{
					path: "module",
					name: "AllModule",
					component: AllModule,
				},
				{
					path: "add-module",
					name: "AddModule",
					component: AddModule,
				},
				{
					path: "edit-module/:idModule",
					name: "EditModule",
					component: EditModule,
				},
        {
          path: "edit-estimasi-status",
          name: "SetEstimasiProses",
          component: () => import("@/views/master/module/SetEstimasiProses"),
        },
			],
		},
		// Master Module
		{
			path: "/master",
			redirect: "/master/module_category",
			name: "Master Kategori Module",
			component: TheContainer,
			children: [
				{
					path: "module-category",
					name: "AllModuleCategory",
					component: AllModuleCategory,
				},
				{
					path: "add-module-category",
					name: "AddModuleCategory",
					component: () => import("@/views/master/module_cat/AddModuleCategory"),
				},
				{
					path: "edit-module-category/:idJenisTanaman",
					name: "EditJModuleCategory",
					component: () => import("@/views/master/module_cat/EditJModuleCategory"),
				},
			],
		},
		// Master Pelabihan
		{
			path: "/master",
			redirect: "/master/pelabuhan",
			name: "Master Pelabuhan",
			component: TheContainer,
			children: [
				{
					path: "pelabuhan",
					name: "AllPelabuhan",
					component: AllPelabuhan,
				},
				{
					path: "add-pelabuhan",
					name: "AddPelabuhan",
					component: () => import("@/views/master/pelabuhan/AddPelabuhan"),
				},
				{
					path: "edit-pelabuhan/:idPelabuhan",
					name: "EditPelabuhan",
					component: () => import("@/views/master/pelabuhan/EditPelabuhan"),
				},
			],
		},
		// Master Tipe Pemohon
		{
			path: "/master",
			redirect: "/master/tipe-pemohon",
			name: "Master Tipe Pemohon",
			component: TheContainer,
			children: [
				{
					path: "tipe-pemohon",
					name: "AllTipePemohon",
					component: AllTipePemohon,
				},
				{
					path: "add-tipe-pemohon",
					name: "AddTipePemohon",
					component: () => import("@/views/master/tipe_pemohon/AddTipePemohon"),
				},
				{
					path: "edit-tipe-pemohon/:idTipePemohon",
					name: "EditTipePemohon",
					component: () => import("@/views/master/tipe_pemohon/EditTipePemohon"),
				},
			],
		},
		// Master Tipe Pemohon
		{
			path: "/master",
			redirect: "/master/role",
			name: "Master Role",
			component: TheContainer,
			children: [
				{
					path: "role",
					name: "AllRole",
					component: () => import("@/views/master/role/AllRole"),
				},
				{
					path: "add-role",
					name: "AddRole",
					component: () => import("@/views/master/role/AddRole"),
				},
				{
					path: "edit-role/:idRole",
					name: "EditRole",
					component: () => import("@/views/master/role/EditRole"),
				},
				{
					path: "konfig-role/:idRole",
					name: "KonfigRole",
					component: () => import("@/views/master/role/KonfigRole"),
				},
			],
		},
		// Master Satuan
		{
			path: "/master",
			redirect: "/master/satuan",
			name: "Master Satuan",
			component: TheContainer,
			children: [
				{
					path: "satuan",
					name: "AllSatuan",
					component: () => import("@/views/master/satuan/AllSatuan"),
				},
				{
					path: "add-satuan",
					name: "AddSatuan",
					component: () => import("@/views/master/satuan/AddSatuan"),
				},
				{
					path: "edit-satuan/:idSatuan",
					name: "EditSatuan",
					component: () => import("@/views/master/satuan/EditSatuan"),
				},
			],
		},
		{
			path: "/master",
			redirect: "/master/status_permohonan",
			name: "Master Negara",
			component: TheContainer,
			children: [
				{
					path: "status_permohonan",
					name: "AllStatusPermohonan",
					component: () => import("@/views/master/status_permohonan/AllStatusPermohonan"),
				},
				{
					path: "add-status-permohonan",
					name: "AddStatusPermohonan",
					component: () => import("@/views/master/status_permohonan/AddStatusPermohonan"),
				},
				{
					path: "edit-status-permohonan/:idStatus",
					name: "EditStatusPermohonan",
					component: () => import("@/views/master/status_permohonan/editStatusPermohonan"),
				},
			],
		},
		// Master aropt
		{
			path: "/master",
			redirect: "/master/aropt",
			name: "Master Aropt",
			component: TheContainer,
			children: [
				{
					path: "aropt",
					name: "AllAropt",
					component: () => import("@/views/master/aropt/AllAropt"),
				},
				{
					path: "add-aropt",
					name: "AddAropt",
					component: () => import("@/views/master/aropt/AddAropt"),
				},
				{
					path: "edit-aropt/:idAropt",
					name: "EditAropt",
					component: () => import("@/views/master/aropt/EditAropt"),
				},
			],
		},
		// Master Bentuk Benih
		{
			path: "/master",
			redirect: "/master/bentuk-benih",
			name: "Master Bentuk Benih",
			component: TheContainer,
			children: [
				{
					path: "bentuk-benih",
					name: "AllBentukBenih",
					component: () => import("@/views/master/bentuk_benih/AllBentukBenih"),
				},
				{
					path: "add-bentuk-benih",
					name: "AddBentukBenih",
					component: () => import("@/views/master/bentuk_benih/AddBentukBenih"),
				},
				{
					path: "edit-bentuk-benih/:idBentukBenih",
					name: "EditBentukBenih",
					component: () => import("@/views/master/bentuk_benih/EditBentukBenih"),
				},
			],
		},
		// Master Tipe FAQ
		{
			path: "/master",
			redirect: "/master/tipe-faq",
			name: "Master Tipe FAQ",
			component: TheContainer,
			children: [
				{
					path: "tipe-faq",
					name: "AllTipeFaq",
					component: () => import("@/views/master/tipe_faq/AllTipeFaq"),
				},
				{
					path: "add-tipe-faq",
					name: "AddTipeFaq",
					component: () => import("@/views/master/tipe_faq/AddTipeFaq"),
				},
				{
					path: "edit-tipe-faq/:idTipeFaq",
					name: "EditTipeFaq",
					component: () => import("@/views/master/tipe_faq/EditTipeFaq"),
				},
			],
		},
		// Master Tipe FAQ
		{
			path: "/master",
			redirect: "/master/faq",
			name: "Master faq",
			component: TheContainer,
			children: [
				{
					path: "faq",
					name: "AllFaq",
					component: () => import("@/views/master/faq/AllFaq"),
				},
				{
					path: "add-faq",
					name: "AddFaq",
					component: () => import("@/views/master/faq/AddFaq"),
				},
				{
					path: "edit-faq/:idFaq",
					name: "EditFaq",
					component: () => import("@/views/master/faq/EditFaq"),
				},
			],
		},
		// Master Dokumen Persyaratan
		{
			path: "/master",
			redirect: "/master/dokumen-persyaratan",
			name: "Master Dokumen Persyaratan",
			component: TheContainer,
			children: [
				{
					path: "dokumen-persyaratan",
					name: "AllDokumenPersyaratan",
					component: () => import("@/views/master/dokumen_persyaratan/AllDokumenPersyaratan"),
				},
				{
					path: "add-dokumen-persyaratan",
					name: "AddDokumenPersyaratan",
					component: () => import("@/views/master/dokumen_persyaratan/AddDokumenPersyaratan"),
				},
				{
					path: "edit-dokumen-persyaratan/:idDokumenPersyaratan",
					name: "EditDokumenPersyaratan",
					component: () => import("@/views/master/dokumen_persyaratan/EditDokumenPersyaratan"),
				},
			],
		},
		// Master Bentuk Benih
		{
			path: "/master",
			redirect: "/master/tujuan-benih",
			name: "Master Tujuan Penggunaan Benih",
			component: TheContainer,
			children: [
				{
					path: "tujuan-benih",
					name: "AllTujuanBenih",
					component: () => import("@/views/master/tujuan_benih/AllTujuanBenih"),
				},
				{
					path: "add-tujuan-benih",
					name: "AddTujuanBenih",
					component: () => import("@/views/master/tujuan_benih/AddTujuanBenih"),
				},
				{
					path: "edit-tujuan-benih/:idTujuanBenih",
					name: "EditTujuanBenih",
					component: () => import("@/views/master/tujuan_benih/EditTujuanBenih"),
				},
				{
					path: "edit-dokumen-permohonan/:idTujuanBenih",
					name: "SetDokumenPermohonan",
					component: () => import("@/views/master/tujuan_benih/SetDokumenPermohonan"),
				},
			],
		},
		// Master User
		{
			path: "/master",
			redirect: "/master/user",
			name: "Master User",
			component: TheContainer,
			children: [
				{
					path: "user/:role",
					name: "AllUser",
					component: AllUser,
				},
				{
					path: "add-user",
					name: "AddUser",
					component: AddUser,
				},
				{
					path: "edit-user/:userID",
					name: "EditUser",
					component: EditUser,
				}
			],
		},
		{
			path: "/master",
			redirect: "/master/file-download",
			name: "File Download",
			component: TheContainer,
			children: [
				{
					path: "file-download",
					name: "AllFileDownload",
					component: () => import("@/views/master/file_download/AllFileDownload"),
				},
				{
					path: "add-file-download",
					name: "AddFileDownload",
					component: () => import("@/views/master/file_download/AddFileDownload"),
				},
				{
					path: "edit-file-download/:file_download_id",
					name: "EditFileDownload",
					component: () => import("@/views/master/file_download/EditFileDownload"),
				}
			],
		},
		{
			path: "/master",
			redirect: "/master/dokumen-permohonan",
			name: "Master User",
			component: TheContainer,
			children: [ 
				{
					path: "dokumen-permohonan",
					name: "AllDokumenPermohonan",
					component: () => import("@/views/master/dokumen_permohonan/AllDokumenPermohonan"),

				},
			]
		},
		{
			path: "/master",
			redirect: "/master/jadwal-libur-pelayanan",
			name: "Jadwal Libur Pelayanan",
			component: TheContainer,
			children: [
			{
				path: "jadwal-libur-pelayanan",
				name: "AllJadwalLiburPelayanan",
				component: AllJadwalLiburPelayanan,
			},
			{
				path: "add-jadwal-libur-pelayanan",
				name: "AddJadwalLiburPelayanan",
				component: AddJadwalLiburPelayanan,
			},
			{
				path: "edit-jadwal-libur-pelayanan/:idJadwal",
				name: "EditJadwalLiburPelayanan",
				component: EditJadwalLiburPelayanan,
			}],
		},
		{
			path: "/generate",
			redirect: "/generate/excel-laporan-benih-perusahaan/1",
			name: "Generate",
			component: TheContainer,
			children: [
				{
					path: "/excel-laporan-benih-perusahaan/:nib",
					name: "ExcelLaporanDataBenihPerusahaan",
					component: () => import("@/views/generate/ExcelLaporanDataBenihPerusahaan"),
				},
      		],
    	},
		{
			path: "/graph",
			redirect: "/dashboard",
			name: "Graph",
			component: TheContainer,
			children: [
			{
				path: "dashboard-hortikultura",
				name: "DashboardHortikultura",
				component: () => import("@/views/dashboard-graph/Hortikultura"),
			},
			{
				path: "widget",
				name: "widget",
				component: () => import("@/views/charts/Charts"),
			}],
		}
	]
}